<template>
  <div>
    <v-app id="inspire" v-if="!showSplash">
      <v-navigation-drawer
        v-model="drawer"
        app
      >
        <v-sheet
          color="grey lighten-4"
          class="pa-4"
        >
          <v-avatar
            class="mb-4"
            color="grey darken-1"
            size="64"
          >
          <img
            :src="require('@/assets/logo.png')"
            alt="Sus Buer Logo"
          >
        </v-avatar>

          <div>Men of Steeldart</div>
        </v-sheet>

        <v-divider></v-divider>

        <v-list>
          <v-list-item
            link
            to="/"
          >
            <v-list-item-icon>
              <v-icon>mdi-trophy</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Freitagsturnier</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="#c00409" dark>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>SuS Buer Dart</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
  </v-app>

  <vue-splash
      v-if="showSplash"
      :show="true"
      :logo="require('@/assets/splash.png')"
      title=""
      color="black"
      :size="300"
      :fixed="true"
    />
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      drawer: null,
      showSplash: true,
    };
  },
  mounted: function() {
    this.initApp();
  },
  methods: {
    initApp: function() {
      setTimeout(this.hideSplash, 2000);
    },
    hideSplash: function() {
      this.showSplash = false;
    },
  }
}
</script>
