<template>
  <div>
    <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card>
              <v-subheader>Turnerinformationen</v-subheader>
              <v-list three-line>
                <template>
                  <v-list-item v-for="(info, index) in turnerinformationen" :key="index">
                    <v-list-item-avatar size="50">
                      <v-list-item-icon>
                        <v-icon v-text="info.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ info.text }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card>
              <v-subheader>Anmeldeinformationen</v-subheader>
              <v-list three-line>
                <template>
                  <v-list-item v-for="(info, index) in anmeldeinformationen" :key="index">
                    <v-list-item-avatar size="50">
                      <v-list-item-icon>
                        <v-icon v-text="info.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ info.text }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-card>
              <v-subheader>Anmeldung</v-subheader>
              <v-list three-line>
                <template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        prepend-icon="mdi-account"
                        label="Vorname"
                        v-model="form.firstname"
                        ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                        prepend-icon="mdi-account"
                        label="Nachname"
                        v-model="form.lastname"
                        ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                        prepend-icon="mdi-mail"
                        label="Email"
                        type="email"
                        v-model="form.email"
                        ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                        prepend-icon="mdi-phone"
                        label="Telefon"
                        type="phone"
                        v-model="form.phone"
                        ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="freePlaces != 0">
                    <v-list-item-content>
                        <v-btn color="red" dark @click="anmelden" v-if="!loading">Anmelden</v-btn>
                        <v-progress-linear
                          indeterminate
                          color="red darken-2"
                          v-if="loading"
                        ></v-progress-linear>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                        Freie Plätze: {{ freePlaces }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'

  import { initializeApp } from 'firebase/app';
  import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore/lite';

  export default {
    name: 'Home',
    data: function() {
      return {
        freePlaces: 0,
        firestore: null,
        loading: false,
        form: {
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
        },
        turnerinformationen: [
          {
            icon: 'mdi-information',
            text: 'Wer sich vorangemeldet hat aber bis 19:30 nicht da ist, verliert diesen Startplatz.',
          },
          {
            icon: 'mdi-file-document',
            text: 'Vor Ort gilt die 3G-Regel, Selbsttest vor Ort möglich!',
          },
        ],
        anmeldeinformationen: [
          {
            icon: 'mdi-clock',
            text: 'Anmeldeschluss: 19:30',
          },
          {
            icon: 'mdi-clock',
            text: 'Start: 20:00 Uhr',
          },
          {
            icon: 'mdi-google-maps',
            text: 'Vereinsheim SuS Buer, Hilgensele 48, 49328 Melle',
          },
          {
            icon: 'mdi-currency-eur',
            text: '5,-€ (3,-€ werden ausgeschüttet, 2,-€ Nutzungspauschale)',
          },
          {
            icon: 'mdi-account-group',
            text: '6x 5er Gruppen, Best of 5 mit anschließender Single K.O-Phase',
          }
        ],
      };
    },
    mounted: function() {
      this.initFirebase();
    },
    methods: {
      initFirebase: async function() {
        const firebaseConfig = {
          apiKey: "AIzaSyC-7MSMC0pvP3NVl2vESImHuLxWEHDGvB0",
          authDomain: "freitagsturnier-21751.firebaseapp.com",
          projectId: "freitagsturnier-21751",
          storageBucket: "freitagsturnier-21751.appspot.com",
          messagingSenderId: "906148668061",
          appId: "1:906148668061:web:773cb09f37fbc262f72a74"
        };

        let app = initializeApp(firebaseConfig);
        this.firestore = getFirestore(app);

        this.freePlaces = 0;
        this.freePlaces = await this.getFreePlaces();
      },
      anmelden: async function() {
        this.setLoading(true);

        let firstname = this.form.firstname;
        let lastname = this.form.lastname;
        let email = this.form.email;
        let phone = this.form.phone;

        if (firstname == "" || firstname == null || lastname == "" || lastname == null || email == "" || email == null || phone == "" || phone == null) {
          Swal.fire('Fehler', 'Bitte alle Felder ausfüllen', 'warning');
          this.setLoading(false);
          return;
        } 

        let docRef = doc(this.firestore, `anmeldungen/${email}`);
        try {
          await setDoc(docRef, {
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
          });
        } catch (error) {
          Swal.fire('Fehler', 'Anmeldung fehlgeschlagen!', 'error');
          this.initFirebase();
          this.setLoading(false);
          return;
        }

        Swal.fire('Erfolgreich', 'Du bist angemeldet!', 'success');
        this.initFirebase();
        this.setLoading(false);
      },
      setLoading: function(value) {
        this.loading = value;
      },
      getFreePlaces: async function() {
        let colRef = collection(this.firestore, 'anmeldungen');
        let result = await getDocs(colRef);
        return 30 - result.docs.length;
      },
    },
  }
</script>
