<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card v-for="(item, i) in anmeldungen" :key="i" class="mt-3">
                        <v-list disabled>
                            <v-subheader>{{ item.firstname }} {{ item.lastname }}</v-subheader>
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item>
                                <v-list-item-icon>
                                    <v-icon v-text="'mdi-mail'"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.email"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                <v-list-item-icon>
                                    <v-icon v-text="'mdi-phone'"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.phone"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                            </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore/lite';

export default {
    name: "Anmeldungen",
    data: function() {
        return {
            firestore: null,
            anmeldungen: [],
        };
    },
    mounted: async function() {
        this.initFirebase();
        this.getAnmeldungen();
    },
    methods: {
        initFirebase: function() {
            const firebaseConfig = {
            apiKey: "AIzaSyC-7MSMC0pvP3NVl2vESImHuLxWEHDGvB0",
            authDomain: "freitagsturnier-21751.firebaseapp.com",
            projectId: "freitagsturnier-21751",
            storageBucket: "freitagsturnier-21751.appspot.com",
            messagingSenderId: "906148668061",
            appId: "1:906148668061:web:773cb09f37fbc262f72a74"
            };

            let app = initializeApp(firebaseConfig);
            this.firestore = getFirestore(app);
        },
        getAnmeldungen: async function() {
            let collectionRef = collection(this.firestore, "anmeldungen");
            let result = await getDocs(collectionRef);
            for (let i = 0; i < result.docs.length; i++) {
                let data = await result.docs[i].data();
                this.anmeldungen.push(data);
            }
        },
    },
}
</script>